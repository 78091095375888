import React from 'react'
import Zoom from 'react-reveal/Zoom'
import landingVideo from '../../images/landing.mp4'

const Landing = () => {
  return (
    <div className='home' id='home'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Zoom>
              <div className='content d-flex flex-column justify-content-center'>
                <h1>More than just capital.</h1>
              </div>
            </Zoom>
          </div>
        </div>
      </div>

      <div className='video'>
        <video preload='true' playsInline loop autoPlay muted>
          <source src={landingVideo} type='video/mp4' />
        </video>
      </div>
    </div>
  )
}

export default Landing
