import React from 'react'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <footer className='mt-auto'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <p>© {year} M Mountain Capital LLC. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
