import Home from './components/home/Home'
import Navbar from './components/shared/Navbar'
import Footer from './components/shared/Footer'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Redirect to='/' />
      </Switch>
      <Footer />
    </Router>
  )
}

export default App
