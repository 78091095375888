import React from 'react'
import Fade from 'react-reveal/Fade'

const About = () => {
  const data = [
    {
      id: 'p_01',
      content: `M Mountain Capital is a private investment platform engaged in the acquisition of dynamic, growing, well-managed
  operating companies based in North America, with a primary focus on businesses headquartered in the United
  States. The Firm has offices in both the United States (Southport, Connecticut) and Mexico (San Pedro,
  Monterrey).`,
    },
    {
      id: 'p_02',
      content: `Led by a team of experienced investors with strong entrepreneurial, financial, and operating experience,
  M Mountain is uniquely positioned to invest in private and family-owned companies looking for more than just
  capital.`,
    },
    {
      id: 'p_03',
      content: `Backed by three well capitalized Family Offices initially built off the proceeds generated by the
  multi-billion dollar sale of Grupo IMSA in 2007, M Mountain has the resources to move quickly and aggressively
  without the bureaucracy of a traditional Fund.`,
    },
    {
      id: 'p_04',
      content: `M Mountain is highly focused on partnering with talented management teams to help drive revenue growth and
  operating efficiencies. The ultimate goal for each investment is to enhance the company's market position,
  build scale, and obtain operating efficiencies and synergies to boost the company's profitability and
  total enterprise value of the business.`,
    },
    {
      id: 'p_05',
      content: `With existing operations, significant experiences, numerous portfolio investments, and deep relationships
  in Mexico and across Latin America, M Mountain is differentiated from the traditional U.S.-based lower middle
  market private equity fund. The Firm will be especially intrigued by investment opportunities that provide
  a path towards using these unique resources.`,
    },
    {
      id: 'p_06',
      content: `The M Mountain platform was built to enable other Family Offices, high net worth individuals, and institutional investors to co-invest alongside M Mountain and benefit from the firm’s operational expertise, geographic diversity, and access to the managerial and strategic resources and relationships of the Investment Team.`,
    },
  ]

  return (
    <div className='about' id='whoWeAre'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3>Who We Are</h3>
          </div>
        </div>

        <Fade>
          <div className='row'>
            <div className='col-lg-5'>
              <h5 className='f30'>Experienced Investors</h5>
            </div>
            <div className='col-lg-7'>
              <p>
                M Mountain Capital is a private investment platform engaged in the acquisition of dynamic, growing, and
                well-managed operating companies based in North America, with a primary focus on businesses
                headquartered in the United States. The firm has offices in both the United States (Southport,
                Connecticut) and Mexico (Monterrey).
              </p>
              <p>
                Led by a team of experienced investors with strong entrepreneurial, financial, and operating experience,
                M Mountain is uniquely positioned to invest in private and family-owned companies looking for more than
                just capital.
              </p>
              <p>
                M Mountain is backed by four well capitalized Family Offices, and it has the resources to move quickly
                and aggressively without the bureaucracy of a traditional fund. Three of the Family Offices were
                initially built from the proceeds generated by the multi-billion dollar sale of Grupo IMSA in 2007, and
                the fourth family office has built one of the largest real estate development companies in Mexico, with
                a variety of residential, commercial and industrial properties.
              </p>
            </div>
          </div>
        </Fade>

        <Fade>
          <div className='row'>
            <div className='col-lg-5'>
              <h5 className='f30'>Operationally Focused</h5>
            </div>
            <div className='col-lg-7'>
              <p>
                M Mountain is highly focused on partnering with talented management teams to help them grow their
                businesses. The goal for each investment is to build scale, enhance the company´s market position,
                optimize its cost structure, and to execute on strategic add-on acquisitions at attractive valuations.
              </p>
            </div>
          </div>
        </Fade>

        <Fade>
          <div className='row'>
            <div className='col-lg-5'>
              <h5 className='f30'>Differentiated Platform</h5>
            </div>
            <div className='col-lg-7'>
              <p>
                Through its existing operations, past experiences, and deep relationships in Mexico and across Latin
                America, M Mountain is differentiated from the traditional U.S.-based lower middle market private equity
                fund. The firm will be especially intrigued by investment opportunities that provide a path towards
                using the unique resources.
              </p>
            </div>
          </div>
        </Fade>

        <Fade>
          <div className='row'>
            <div className='col-lg-5'>
              <h5 className='f30'>Investment Partnerships</h5>
            </div>
            <div className='col-lg-7'>
              <p>
                The M Mountain platform was built to enable other family offices, high net worth individuals, and
                institutional investors to co-invest alongside M Mountain and benefit from the firm’s operational
                expertise, geographic diversity, and access to the managerial and strategic resources and relationships
                of the M Mountain investment team.
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default About
