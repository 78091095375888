import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import TeamModal from './Modal'
import PeterPJSmithPhoto from '../../images/team/Peter_PJ_Smith.jpg'
import ErnestoLopezClariondPhoto from '../../images/team/Ernesto_Lopez_Clariond.jpg'
import FedericoClariondDomenePhoto from '../../images/team/Federico_Clariond_Domene.jpg'
import FernandoCanalesStelzerPhoto from '../../images/team/Fernando_Canales_Stelzer.jpg'
import SuzanneKaramFazPhoto from '../../images/team/Suzanne_Karam_Faz.jpg'
import DorelyLermaSantosPhoto from '../../images/team/Dorely_Lerma_Santos.jpg'
import FranciscoGarzaPhoto from '../../images/team/Francisco_Garza.jpg'
import RidgeCromwellPhoto from '../../images/team/Ridge_Cromwell.jpg'
import VictorMohamarServinPhoto from '../../images/team/Victor_Mohamar_Servin.jpg'
import teamHolderPhoto from '../../images/team/team_holder.jpg'

const Team = () => {
  const data = [
    {
      id: 't_01',
      name: 'Peter (“PJ”) Smith',
      title: 'Managing Partner',
      img_src: PeterPJSmithPhoto,
      bio: `Mr. Peter (“PJ”) Smith spent the past 11 years at Starboard Capital Partners, a CT-based private equity sponsor focused on the lower middle market. Leading three platform transactions and investing in six other portfolio companies during his tenure at Starboard, Mr. Smith currently remains on the Board of Directors at PureRED and Theraplay. Prior to joining Starboard in 2009, he founded advisory firm Canwell Capital after working briefly at the asset management firm Birinyi Associates. Mr. Smith began his finance career at Bear, Stearns & Co., spending 7 years in Debt Capital Markets and on the Investment Grade Debt Syndicate desk. Mr. Smith graduated with a BA in Economics from Middlebury College.`,
      linkedin: 'https://www.linkedin.com/in/pj-smith-0b371a6/',
      email: 'psmith@mmountaincap.com',
    },
    {
      id: 't_02',
      name: 'Ernesto Lopez Clariond',
      title: 'Partner',
      img_src: ErnestoLopezClariondPhoto,
      bio: `Mr. Ernesto Lopez Clariond is currently the founder and Chairman of Empresas Aries, a diversified investment group, and Banco Bancrea, one of the 20 largest banking institutions in Mexico. Over the past 20 years, he has built several start-up companies in diverse industries, achieving early profitability and expanding operations through organic and inorganic growth, some of which have been divested successfully as of today. The first of these was Aries Coil, an industrial coatings company, sold to Valspar in 2007. Subsequently, he founded Grupo Diagnóstico Aries, a family of seven healthcare diagnostic brands and the second largest diagnostics group in Mexico serving over 2.5 million patients annually in 164 locations. Swiss Hospital, another successful investment which focuses on surgical specialties, was opened in 2013. Having now built a strong doctor networks, Swiss Hospital serves approximately 6,000 patients per year. Aries Capital, the family office investment vehicle, has made investments in private equity, real estate and third-party financing exceeding $300 million. In the firm’s housing division, seven urban residential projects have been completed since 2012 with over 3,100 houses delivered to middle income families. Mr. Lopez holds a BA in Administration from the Monterrey Institute of Technology and an MBA from the University of Monterrey.`,
      email: 'elopezc@mmountaincap.com',
    },
    {
      id: 't_03',
      name: 'Federico Clariond Domene',
      title: 'Partner',
      img_src: FedericoClariondDomenePhoto,
      bio: `Mr. Federico Clariond Domene began his career at Cemex and joined Grupo IMSA after receiving his MBA. After the sale of Grupo IMSA, he founded his own family office, Valores Aldabra, in 2011. Among other numerous investments, Mr. Clariond acquired EMATEC, a company that is now the 3rd largest sustainable packaging company in North America, he co-founded Banco Bancrea alongside Mr. Lopez, and participated in an early round for Betterware, the first Mexican company to list on Nasdaq (BWMX). He currently serves as a Director for numerous public and private companies, including Grupo Cuprum, Afore Invercap, Fibra Plus and Betterware. Mr. Clariond holds a BA in Mechanical Engineering from the Monterrey Institute of Technology and an MBA from Stanford.`,
      linkedin: 'https://www.linkedin.com/in/federico-clariond-domene-7aa49310a/',
      email: 'fclariond@mmountaincap.com',
    },
    {
      id: 't_04',
      name: 'Fernando Canales Stelzer',
      title: 'Partner',
      img_src: FernandoCanalesStelzerPhoto,
      bio: `Mr. Fernando Canales Stelzer began his career as an Investment Banker at Abaco Casa de Bolsa/Rodman & Renshaw in Monterrey, Mexico and Chicago, IL. After his MBA, he continued his Investment Banking career in New York City at Salomon Brothers. In 2000 he joined his family company, Grupo IMSA, as a Director in IMSA’s Steel Division. Mr. Canales led the sale of Grupo IMSA in 2006/2007. He is currently Managing Director at Corporación Finestra, his family’s private capital fund with $1.0 billion AUM, which has investments in various industries such as Engineered Materials, Energy, Telecom, Finance, and Real Estate across Mexico, the United States, and Europe. Mr. Canales holds a BA in Economics from Monterrey Institute of Technology and an MBA from The Wharton School at the University of Pennsylvania.`,
      email: 'fcanales@mmountaincap.com',
    },
    {
      id: 't_05',
      name: 'Victor Mohamar Servin',
      title: 'Partner',
      img_src: VictorMohamarServinPhoto,
      bio: `Mr. Victor Mohamar Servin is one the majority owners and Managing Director of Grupo Davisa, a real estate development group that invests in a wide range of sectors including industrial, residential, mixed-use, commercial, and retail throughout Mexico. Grupo Davisa was founded in 1980 and has achieved double-digit growth from 2000 to date. It currently owns and/or manages almost 10 million square feet of income-producing properties and sells 2,000 homes per year. Mr. Mohamar interacts on a daily basis with senior executives of international companies from the U.S., Canada, Germany, China, and Korea as Davisa guides them though their expansion and/or relocation into Mexico within Davisa’s Industrial Park network. Davisa also partners with large private equity firms and REITs for the development of stabilized portfolios of industrial buildings. Mr. Mohamar graduated with honors holding a BA in Mechanical Engineering from the Monterrey Institute of Technology.`,
      email: 'vmohmar@mmountaincap.com',
    },
    {
      id: 't_06',
      name: 'Ridge Cromwell',
      title: 'Chief Financial Officer',
      img_src: RidgeCromwellPhoto,
      bio: `Mr. Ridgely (“Ridge”) Cromwell has a diverse background as both private equity investor and company operator, having lead deals as investor as well as directly serving as CFO/COO with a variety of lower middle market companies. Mr. Cromwell started his finance career working in leveraged finance for five years with Chase Manhattan Bank and Creditanstalt, has spent 15 years in private equity investing with Craig Capital Corporation, Wolfjaw Capital, and Starboard Capital Partners, and most recently as CFO/COO in three private equity backed companies for the past seven years.  Mr. Cromwell has been the lead or co-investor in 15 private equity transactions across multiple industries including healthcare, manufacturing, and consumer. Mr. Cromwell graduated with a BA from Trinity College and has an MBA from Yale School of Management.`,
      linkedin: 'https://www.linkedin.com/in/ridgelycromwell/',
      email: 'rcromwell@mmountaincap.com',
    },
    {
      id: 't_07',
      name: 'Joseph Sokol',
      title: 'Vice President',
      img_src: teamHolderPhoto,
      bio: `Joseph Sokol joined M Mountain Capital as a Vice President in 2021. He is focused on new business development, due diligence, deal execution, and portfolio company management. Joe is currently on the Board of Victor Technology. Prior to M Mountain, Joe served as a Senior Associate at Heartwood Partners (formerly known as Capital Partners) and RFE Investment Partners, where he worked alongside families, entrepreneurs, and other founder-operators to help grow their businesses. He has completed several platform and add-on acquisitions and has been a valuable strategic resource for management teams in a variety of industries, including business services, niche manufacturing, and consumer/retail. Joe began his career in Investment Banking serving as an Analyst at Sawaya Partners and Barclays Capital in its Global Consumer Group. He graduated summa cum laude from Loyola University Maryland with a Bachelor of Business Administration Degree in Finance. Joe lives in Westchester County, NY. In his spare time, he enjoys playing golf and spending quality time with his family and friends.`,
      linkedin: 'https://www.linkedin.com/in/joseph-sokol-29a1b012/',
      email: 'jsokol@mmountaincap.com',
    },
    {
      id: 't_10',
      name: 'Francisco Garza',
      title: 'Financial Advisor',
      img_src: FranciscoGarzaPhoto,
      bio: `Mr. Francisco Garza is the Financial Advisor at Mr. Clariond’s family office, Valores Aldabra. He is instrumental in the investment process, valuation, due diligence, and financing for their various investments. Throughout his career, Mr. Garza has worked as CFO for several companies across multiple sectors including Real Estate, Food and Beverage, and Retail. He earned his CFA degree from The Monterrey Institute of Technology and has a Masters from DUXX Graduate School of Business Leadership. For more than 5 years, Mr. Garza served as a professor of finance at The Monterrey Institute of Technology.`,
      linkedin: 'https://www.linkedin.com/in/francisco-garza-96351a6b/',
    },
  ]

  const [show, setShow] = useState(false)
  const [carIndex, setCarIndex] = useState(0)

  const handleModalClick = (index) => {
    setShow(!show)
    setCarIndex(index)
  }

  const handleSelect = (selectedIndex, e) => {
    setCarIndex(selectedIndex)
  }

  return (
    <React.Fragment>
      <div className='team' id='team'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h3>Team</h3>
            </div>
          </div>
          <div className='row'>
            {data.map((item, index) => {
              return (
                <Fade key={item.id}>
                  <div className='col-md-6' onClick={() => handleModalClick(index)}>
                    <div className='person d-flex flex-column flex-md-row trans'>
                      <div className='img-container'>
                        <img src={item.img_src} alt={item.name} className='img-contain' />
                      </div>
                      <div className='info d-flex flex-column'>
                        <h5 className='name f18'>{item.name}</h5>
                        <h5 className='title f16'>{item.title}</h5>
                        <h5 className='desc'>{item.bio}</h5>
                        <h5 className='read-more'>Read More</h5>
                      </div>
                    </div>
                  </div>
                </Fade>
              )
            })}
          </div>
        </div>
      </div>

      <TeamModal
        show={show}
        handleClose={handleModalClick}
        handleSelect={handleSelect}
        carIndex={carIndex}
        data={data}
      />
    </React.Fragment>
  )
}

export default Team
