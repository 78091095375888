import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import efcLogo from '../../images/MMountain_logo.png'
import overviewPDF from '../../images/pdf/MM_Capital_Overview.pdf'

const Navigation = () => {
  const [navbarMin, setNavbarMin] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 10) {
        setNavbarMin('navbar-min')
      } else {
        setNavbarMin('')
      }
    })
  }, [])

  return (
    <Navbar className={`navbar ${navbarMin} fixed-top flex-column trans`} expand='lg' collapseOnSelect>
      <div className='container'>
        <a href='#home' className='navbar-brand ml-lg-0'>
          <img src={efcLogo} className='img-fluid trans' alt='M Mountain logo' />
        </a>
        <Navbar.Toggle aria-controls='myNav'>
          <i className='fas fa-bars'></i>
        </Navbar.Toggle>
        <Navbar.Collapse id='myNav'>
          <Nav className='navbar-nav align-items-center ml-auto'>
            <Nav.Link href='#whoWeAre' className='nav-item nav-link'>
              Who We Are
            </Nav.Link>
            <Nav.Link href='#heritage' className='nav-item nav-link'>
              Heritage
            </Nav.Link>
            <Nav.Link href='#strategy' className='nav-item nav-link'>
              Strategy
            </Nav.Link>
            <Nav.Link href='#investments' className='nav-item nav-link'>
              Investments
            </Nav.Link>
            <Nav.Link href='#team' className='nav-item nav-link'>
              Team
            </Nav.Link>
            <Nav.Link href='#contact' className='nav-item nav-link'>
              Contact
            </Nav.Link>
            <Link to={overviewPDF} target='_blank' className='nav-item nav-link pr-lg-0 mr-lg-0'>
              Firm Overview
            </Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Navigation
