import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import CompaniesModal from './Modal'
import apothocareLogo from '../../images/companies/apothocare.jpg'
import ariesLogo from '../../images/companies/ariescapital.jpg'
import bancobancreaLogo from '../../images/companies/bancobancrea.jpg'
import grupodiagnosticoLogo from '../../images/companies/grupodiagnostico.png'
import jpcLogo from '../../images/companies/jpc.jpg'
import koraLogo from '../../images/companies/kora.jpg'
import pureredLogo from '../../images/companies/purered.jpg'
import swisshospitalLogo from '../../images/companies/swiss_hospital.jpg'
import theraplayLogo from '../../images/companies/theraplay.jpg'
import cuprumLogo from '../../images/companies/grupo_cuprum.png'
import betterwareLogo from '../../images/companies/betterware.png'
import ematecLogo from '../../images/companies/ematec.png'
import verzatecLogo from '../../images/companies/verzatec.png'
import victorLogo from '../../images/companies/victor.jpg'
import fronteraLogo from '../../images/companies/frontera.jpg'
import onDeckSportsLogo from '../../images/companies/on_deck_sports.png'

const Companies = () => {
  const data = [
    {
      id: 'p_14',
      title: 'Victor Technology',
      img_src: victorLogo,
      bio: `Victor Technology, headquartered 30 miles southwest of Chicago, is a fast-growing leader in the branded office products manufacturing and distribution industry. The Company’s brands, including Victor, Officemate, and Sharp Calculators, are among the oldest and best recognized in the office supply channel. These brands hold strong market share positions in many product categories, such as calculators, paper clips, standing desks, clipboards, and desk accessories. The Company’s manufacturing operations and diversified offshore supply chain produce high quality products which are sold across a highly diverse distribution channel, including Amazon, Grainger, Staples, Office Depot, Kroger and large office supply wholesalers and dealers throughout the U.S., Canada and Latin America.`,
      website: `https://www.victortech.com/`,
    },
    {
      id: 'p_15',
      title: 'Frontera Strategies',
      img_src: fronteraLogo,
      bio: `Frontera Strategies is a Dallas-based, leading provider of mobile, in-office cardiac, pulmonary, vascular, and neurological diagnostic testing and imaging services. Uniquely positioned in a large and significantly under penetrated market, the Company provides tremendous benefits to patients and physician practices alike. With an extensive testing menu with best-in-class equipment for advanced testing capabilities delivered by highly skilled and trained clinicians, Frontera’s scalable business model with a highly engaged workforce and fully formed back-office infrastructure in place serves a large, recurring client base. The Company’s proven management team has substantial diagnostics and other relevant healthcare experience and leads clinical teams currently working across seven states.`,
      website: `https://www.teamfrontera.com/`,
    },
    {
      id: 'p_15',
      title: 'On Deck Sports',
      img_src: onDeckSportsLogo,
      bio: `Founded in 2001 and headquartered in Randolph, MA, with additional operations in Chatsworth, GA, ODS is a leading manufacturer and distributor of baseball and softball equipment, as well as a leading designer, developer, and installer of indoor baseball and softball training facilities. The Company's products, which are sold through direct, wholesale, and online channels, include turf, netting, flooring, training aids, protective screens, balls, pitching machines, and mobile pitching mounds, making it a one-stop-shop for baseball and softball teams.  The Company offers its ~2,500 SKUs and facility services to colleges & universities, high schools, youth programs, residential customers, and private sports facilities across the United States.  Recently, the Company has leveraged its expertise in turf, netting, and indoor sports facilities to expand its product and service offerings to soccer and golf customers.`,
      website: `https://www.ondecksports.com/`,
    },
    {
      id: 'p_01',
      title: 'Apothecare',
      img_src: apothocareLogo,
      bio: `Apothecare Pharmacy, LLC (“Apothecare”) is a rapidly growing institutional pharmacy servicing behavioral health patients living in group homes and community-based settings across Massachusetts. The company serves more than 6,000 patients in more than 700 group homes and other settings across the state.`,
      website: `https://apothecareinc.com`,
    },
    {
      id: 'p_02',
      title: 'Aries Capital',
      img_src: ariesLogo,
      bio: `Aries Capital is a family-owned investment vehicle in Mexico which seeks and capitalizes on investment opportunities in  private equity, real estate and third-party financing with a robust Deal Flow driven from active searches and a strong network.`,
    },
    {
      id: 'p_03',
      title: 'Banco Bancrea',
      img_src: bancobancreaLogo,
      bio: `Banco Bancrea founded in 2013 and headquartered in Monterrey, Mexico focuses on financing to  small and middle-sized companies and currently operates in 13 locations in eight states. Since inception it has been the most successful and rapidly growing bank in Mexico, obtaining its investment grade ratings from major Rating Agencies. Its success is based on its adherence to its business model: a) focus on client experience through personalized service and quick response times, b) tap into the potential of a deeply underserved market, c) sustained growth and profitability with strict disciplines towards risk management, d) long-standing track record in management and corporate governance.`,
      website: `http://www.bancrea.com/`,
    },
    {
      id: 'p_04',
      title: 'Better Ware',
      img_src: betterwareLogo,
      bio: `Betterware de Mexico is a leading direct-to-consumer company in Mexico. Betterware specializes in the home organization segment, with a wide product portfolio for daily solutions including organization, kitchen preparation, food containers, and smart furniture, among others. We participated in an early round in late 2018 with a seat on the board of directors. Since then, the Company went public through a SPAC merger and became the first Mexican company to be listed on Nasdaq (BWMX). Today the company has more than 1.2 million associates that sell its product throughout Mexico.`,
      website: `http://www.betterware.com.mx/`,
    },
    {
      id: 'p_05',
      title: 'EMATEC',
      img_src: ematecLogo,
      bio: `EMATEC is a packaging company that specializes in molded pulp packaging for eggs, fruit trays, and food service trays. Since its acquisition in 2014, the Company’s EBITDA has grown two-fold, introduced new products, and increased margins through an aggressive investment program. Debt refinancing, a long-term management incentive plan, and a customer centric culture have allowed the Company to increase its market share both in Mexico and the US. Keeping with its environmental objectives, the Company invested in producing 60% of its electricity needs while using the excess heat of the turbines in the manufacturing drying ovens. EMATEC currently operates six manufacturing facilities and employs 560 people.`,
      website: `http://www.ematec.com.mx/`,
    },
    {
      id: 'p_06',
      title: 'Grupo Cuprum',
      img_src: cuprumLogo,
      bio: `With more than 60 years of experience in the industry, Cuprum is the largest aluminum extruder in Latin America. With the support of a solid team, it has been able to consolidate itself as Mexico's leader in the production of ladders and prefabricated windows. Also, through the nation’s largest chain of stores, Cuprum became a leader in the commercialization of aluminum shapes. Outside Mexico, Cuprum is the most important ladder marketer in Canada and Latin America, and second largest in the United States.`,
      website: `http://www.cuprum.com/`,
    },
    {
      id: 'p_07',
      title: 'Grupo Diagnostico Aries',
      img_src: grupodiagnosticoLogo,
      bio: `Grupo Diagnóstico Aries, a leading medical diagnosis and imageology player in México, was founded in 2012. It operates seven brand names across 164 locations, and 2,500 employees delivering quality services to Mexican families and reaching over 2.5 million patients annually. Its central laboratory under the Diagnostix brand, opened in 2020, is the most modern laboratory in Mexico with first-class equipment, offering the widest range of services including third-party reference. Excellence driven, the backbone that supports the operation is a comprehensive management model that prioritizes quality, patient safety, and trust.`,
      website: `https://grupodiagnosticoaries.com/`,
    },
    {
      id: 'p_08',
      title: 'Jersey Precast Corp.',
      img_src: jpcLogo,
      bio: `Jersey Precast Corp. (“JPC”), founded in 1980, provides quality precast and pre-stressed concrete products to the Tri-state and surrounding areas. With facilities in New Jersey and Pennsylvania and almost 300,000 square feet of manufacturing space under roof, precast units weighing up to 50 tons are routinely handled. The Company’s fabrication facilities are backed up by a strong engineering department comprised of engineers and AUTOCAD operators. All engineering and shop drawings are performed in-house enabling JPC to provide significant support to designers and resolve any design related issues immediately.`,
      website: `http://www.jerseyprecast.com/`,
    },
    {
      id: 'p_09',
      title: 'Kora Desarrollos',
      img_src: koraLogo,
      bio: `Kora Desarrollos was founded in 2011 as a housing developer of urban residential communities for middle income families in México. To date, seven residential projects have been successfully completed and delivered with over 3,100 houses sold under a unique business model targeting premium quality alongside strict operating processes.`,
      website: `http://www.kora.com.mx/`,
    },
    {
      id: 'p_10',
      title: 'PureRed',
      img_src: pureredLogo,
      bio: `PureRED, founded in 1967, designs, produces, and implements consumer marketing programs and content for large national retailers. The Company delivers strategy, creative, design, layout, data management and photography across digital, social, ecommerce, paper and in-store physical media systems. With 12 offices across the United States, the Company is headquartered in Stone Mountain, Georgia.`,
      website: `http://www.purered.net/`,
    },
    {
      id: 'p_11',
      title: 'Swiss Hospital',
      img_src: swisshospitalLogo,
      bio: `Swiss Hospital was opened in 2013 in Monterrey, Mexico as a center of surgical specialties care. Its business model drivers are its strong doctor network, its warm and personalized patient care, and its excellence driven quality management and operations support. Swiss Hospital serves approximately 6,000 patients per year.`,
      website: `http://www.swisshospital.mx/`,
    },
    {
      id: 'p_12',
      title: 'Therapy',
      img_src: theraplayLogo,
      bio: `Theraplay is a Pennsylvania-based outpatient pediatric therapy practice and school-based contract therapy provider. As one of the largest networks in the Northeast United States, the Company is recognized as a leader in providing physical, occupational, speech, and feeding therapy services, treating children from birth through 21. With more than 30 clinics across PA, NJ, VA, and DE, Theraplay employs approximately 1,000 clinicians who provide more than 800,000 therapy treatments per year.`,
      website: `http://www.theraplayinc.com/`,
    },
    {
      id: 'p_13',
      title: 'Verzatec',
      img_src: verzatecLogo,
      bio: `With over 60 years of experience and based at the north of Mexico in Monterrey, Verzatec Group subsidiaries are the world’s leading companies in the manufacture and sale of fiberglass-reinforced plastic, polycarbonate sheets, and interior and exterior panels for the building and transportation industries. This leadership comes from the use of leading-edge technology in plant and manufacturing processes and on the high quality and great variety of its product portfolio, which is tailor-made to meet its customer’s needs. The Group has operating facilities in US, Mexico, Spain, and Switzerland.`,
      website: `http://www.verzatec.com/`,
    },
  ]

  const [show, setShow] = useState(false)
  const [carIndex, setCarIndex] = useState(0)

  const handleModalClick = (index) => {
    setShow(!show)
    setCarIndex(index)
  }

  const handleSelect = (selectedIndex, e) => {
    setCarIndex(selectedIndex)
  }

  return (
    <React.Fragment>
      <div className='companies' id='investments'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h3>Representative Investments</h3>
              <p className='mb-lg-3'>
                M Mountain Capital’s investment professionals have invested in, managed, and/or served on the board of a
                variety of companies including those outlined below.
              </p>
            </div>
          </div>
          <div className='row'>
            {data.map((item, index) => {
              return (
                <React.Fragment>
                  {index === 0 ? (
                    <div className='col-12'>
                      <h5 className='filter-title'>Current Investments</h5>
                    </div>
                  ) : index === 3 ? (
                    <div className='col-12'>
                      <h5 className='filter-title'>Prior Investments</h5>
                    </div>
                  ) : null}
                  <Fade key={item.id}>
                    <div className='col-6 col-md-4 col-lg-3' onClick={() => handleModalClick(index)}>
                      <div className='img-container trans'>
                        <img src={item.img_src} alt={item.title} className='img-contain' />
                      </div>
                    </div>
                  </Fade>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>

      <CompaniesModal
        show={show}
        handleClose={handleModalClick}
        handleSelect={handleSelect}
        carIndex={carIndex}
        data={data}
        modalname='CompanyModal'
      />
    </React.Fragment>
  )
}

export default Companies
