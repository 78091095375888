import React from 'react'
import strategyPieLogo from '../../images/art/sector_focus_pie.png'

const InvestmentCriteria = () => {
  return (
    <div className='investment-criteria'>
      <div className='container'>
        <div className='row'>
          <div className='img-container w-100 d-flex flex-column'>
            <img src={strategyPieLogo} alt='Strategy Pie' className='img-fluid m-auto' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvestmentCriteria
