import React from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

const SectorFocus = () => {
  return (
    <div className='sector-focus' id='strategy'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3>Strategy</h3>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-5'>
            <div className='h-100 d-flex flex-column'>
              <div className='main mt-0'>
                <h5 className='num f35'>
                  <i>Up to</i> <br />$
                  <CountUp end={200} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  MM
                </h5>
                <h5 className='desc f25'>Revenue</h5>
              </div>
              <div className='main'>
                <h5 className='num f35'>
                  $
                  <CountUp end={2} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  -
                  <CountUp end={20} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  MM
                </h5>
                <h5 className='desc f25'>EBITDA</h5>
              </div>
              <div className='main'>
                <h5 className='num f35'>
                  <CountUp end={10} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  %+
                </h5>
                <h5 className='desc f25'>EBITDA Margins</h5>
                <h5 className='desc f20'>
                  <i>with an opportunity to improve margins through increased scale and operating efficiencies</i>
                </h5>
              </div>
            </div>
          </div>
          <div className='col-lg-7'>
            <p>Companies headquartered in North America</p>
            <p>
              Strong management teams who would benefit from additional capital resources and{' '}
              <span>operationally-focused</span> investment partners
            </p>
            <p>Proprietary or differentiated technology, products, services, or customer relationships</p>
            <p>Fragmented industry sectors ripe for consolidation</p>
            <p>
              <span>Large</span> and <span>growing end</span> markets
            </p>
            <p>
              Potential, but not necessary, for portfolio companies to tap into{' '}
              <span>M Mountain Capital’s Mexican resources</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectorFocus
