import React from 'react'
import Zoom from 'react-reveal/Zoom'

const History = () => {
  const data = [
    {
      id: 'h_01',
      year: '1936',
      title: `Industrias Monterrey, S.A. (Grupo IMSA) was founded`,
      description: `Industrias Monterrey, S.A. (Grupo IMSA) was founded by Mr. Eugenio Clariond Garza and Mrs. Consuelo Clariond de Canales`,
    },
    {
      id: 'h_02',
      year: '1936-2007',
      title: `Grupo IMSA became Mexico's largest`,
      description: `Grupo IMSA became Mexico's largest intermediate steel processor in terms of sales volume. With extensive operations in the United States, Argentina, Brazil, Chile, and Venezuela, Grupo  IMSA  also became the  largest  automotive  starting,  lighting,  and ignition battery producer in Mexico, the largest producer of fabricated aluminum products in  Mexico,  and  one  of  the  largest  producers  of  prefabricated  steel  and  foam-insulated panels in North America.`,
    },
    {
      id: 'h_03',
      year: '1999',
      title: `Aries  Coil`,
      description: `Aries  Coil,  dedicated  to  the  production  of  industrial  coatings,  is  founded  by  Mr. Lopez.`,
    },
    {
      id: 'h_04',
      year: '2000-2007',
      title: `Mr. Clariond and Mr. Canales`,
      description: `Mr. Clariond and Mr. Canales worked at the family company (Grupo IMSA) where  they  acquired  significant  financial  and  operating  experience  as  well  as  the entrepreneurial spirit of their family.`,
    },
    {
      id: 'h_05',
      year: '2007',
      title: `Grupo  IMSA  was  sold`,
      description: `Grupo  IMSA  was  sold  to  Ternium  in  a  transaction  worth  US$3.2  Billion  Total Enterprise Value.`,
    },
    {
      id: 'h_06',
      year: '2007',
      title: `Aries Coil is sold`,
      description: `Aries Coil is sold to Valspar. After that, Mr. Lopez creates Empresas Aries, both as a family office and investment vehicle. `,
    },
    {
      id: 'h_07',
      year: '2007',
      title: `Mr. Canales launches family investment firm`,
      description: `Mr. Canales, along with his father and brother, founded Corporación Finestra, SA de CV as their private investment vehicle providing both debt and equity capital to businesses in numerous sectors including Industrials, Oil & Gas, Telecom, and Real Estate across the U.S., Mexico, and Europe.`,
    },
    {
      id: 'h_08',
      year: '2011',
      title: `Mr. Clariond founded family office`,
      description: `Mr. Clariond founded family office Valores Aldabra, focusing on investments in the financial services industry, aluminum, real estate, sustainable packaging and consumer goods firms, and other projects.`,
    },
    {
      id: 'h_09',
      year: '2013',
      title: `Mr. Lopez founded Bancrea`,
      description: `Mr. Lopez founded Bancrea, one of the fastest growing financial institutions in Mexico. Mr. Clariond participates in the Bank’s founding alongside Mr. Lopez.`,
    },
    {
      id: 'h_10',
      year: '2013-2020',
      title: `Investments in multiple companies`,
      description: `Mr. Lopez, Mr. Clariond, and Mr. Canales separately invest in multiple companies  including:  Kora  Desarrollos,  Swiss  Hospital,  Aries  Capital,  Betterware, Grupo Diagnostico Aries, currently the second largest Diagnostics Group in Mexico, and EMATEC, currently the third largest molded pulp packaging company in North America.`,
    },
    {
      id: 'h_11',
      year: '2020',
      title: `Creation of a new investment partnership`,
      description: `After successful individual careers, Mr. Lopez, Mr. Clariond, and Mr. Canales decide to create a new investment  partnership as their families previously did in 1936.  They determined their initial collaboration would be to invest in lower middle market North American operating companies, hiring PJ Smith to lead their efforts in the United States.`,
    },
    {
      id: 'h_12',
      year: '2021',
      title: `M Mountain Capital is formed`,
      description: `M Mountain Capital is formed and officially launched. Mr. Victor Mohamar Servin joins M Mountain Capital as a fourth general partner.`,
    },
    {
      id: 'h_13',
      year: '2021',
      title: `M Mountain Capital invests in Victor Technology`,
      description: `M Mountain Capital partners with the owners and management team to recapitalize Victor Technology, which manufactures, sources, and distributes branded and private label workplace, home office and classroom products.`,
    },
    {
      id: 'h_14',
      year: '2022',
      title: `M Mountain Capital invests in Frontera Strategies`,
      description: `M Mountain Capital recapitalizes and provides growth capital to Frontera Strategies, the leading provider of mobile, in-office diagnostic heart, lung, vascular, and neurological testing services for physician practices.`,
    },
    {
      id: 'h_15',
      year: '2022',
      title: `M Mountain Capital invests in On Deck Sports`,
      description: `M Mountain Capital partners with the founder and management to recapitalize On Deck Sports, a leading manufacturer and distributor of baseball and softball equipment, as well as a leading designer and installer of indoor baseball and softball training facilities.`,
    },
  ]

  return (
    <div className='history' id='heritage'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3>Heritage</h3>
          </div>
        </div>
        <div className='row'>
          {data.map((item) => {
            return (
              <Zoom key={item.id}>
                <div className='col-lg-6'>
                  <div className='main'>
                    <h5 className='year f18'>{item.year}</h5>
                    <h5 className='title f20'>{item.title}</h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              </Zoom>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default History
