import React from 'react'
import { Modal, Carousel } from 'react-bootstrap'

const TeamModal = (props) => {
  return (
    <Modal show={props.show} size='lg' onHide={props.handleClose}>
      <Modal.Header>
        <button onClick={props.handleClose} className='close'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='36'
            height='36'
            fill='currentColor'
            class='bi bi-x'
            viewBox='0 0 16 16'
          >
            <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' />
          </svg>
        </button>
      </Modal.Header>
      <Modal.Body>
        <Carousel interval={null} activeIndex={props.carIndex} onSelect={props.handleSelect}>
          {props.data.map((item) => {
            return (
              <Carousel.Item key={item.id}>
                <div className='row'>
                  <div className='container'>
                    <div className='col-12'>
                      <div className='img-container d-flex flex-column'>
                        <img src={item.img_src} alt={item.name} className='img-contain' />
                      </div>
                      {item.name && <h5 className='name f20'>{item.name}</h5>}
                      {item.title && props.modalname !== 'CompanyModal' && <h5 className='title f18'>{item.title}</h5>}
                      <div className='info'>
                        {item.email && (
                          <h5 className='f18'>
                            <a href={`mailto:${item.email}`}>
                              <i className='fas fa-envelope'></i> {item.email}
                            </a>
                          </h5>
                        )}
                        {item.linkedin && (
                          <h5 className='f18'>
                            <a href={item.linkedin} target='_blank'>
                              <i className='fab fa-linkedin'></i> LinkedIn
                            </a>
                          </h5>
                        )}
                        {item.website && (
                          <h5 className='f18'>
                            <a href={item.website} target='_blank'>
                              <i className='fas fa-globe'></i> Website
                            </a>
                          </h5>
                        )}
                      </div>
                      {item.bio && <p className='bio f18'>{item.bio}</p>}
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            )
          })}
        </Carousel>
      </Modal.Body>
    </Modal>
  )
}

export default TeamModal
