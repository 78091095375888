import React from 'react'
import Landing from './Landing'
import About from './About'
import History from './History'
import InvestmentCriteria from './InvestmentCriteria'
import SectorFocus from './SectorFocus'
import Companies from './Companies'
import Team from './Team'
import Contact from './Contact'

const Home = () => {
  return (
    <React.Fragment>
      <Landing />
      <About />
      <History />
      <SectorFocus />
      <InvestmentCriteria />
      <Companies />
      <Team />
      <Contact />
    </React.Fragment>
  )
}

export default Home
